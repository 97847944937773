<template>
    <section class="profile-creator">
        <div class="container-fluid">
            <div class="d-flex" @click="$router.push({ name : 'My-Quiz' })" type="button">
                <img src="@/assets/images/icons/back-icon.svg" alt="">
                <h3 class="ml-2">Kembali</h3>
            </div>

            <div class="profile mt-3">
                <div class="card">
                    <img src="@/assets/images/icons/bg-profile.png" alt="" class="position-absolute w-100 bg-profile">
                    <div class="card-body mt-5 pt-5 mb-1">
                        <div class="w-100 position-relative d-flex bd-highlight">
                            <div class=" shadow-lg circle-w mt-4 ml-4 d-flex align-items-center justify-content-center">
                                <img v-if="userInfo.picture != null && userInfo.picture != ''" :src="userInfo.picture" alt="" class="profile-pic">
                                <img v-else :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + userInfo.name" alt="" class="profile-pic">
                            </div>
                            <div class="flex-grow-1 ml-4 mt-5 pt-5 d-flex justify-content-between">
                                <div class="text-left">
                                    <h4 class="text-dark font-weight-bold mb-0 fs-18">{{userInfo.name}}</h4>
                                    <p class="text-dark text-muted mb-0">Creator</p>
                                </div>
                                <div class="w-25">
                                    <div class="d-flex">
                                        <div class="col-4">
                                            <h4 class="fs-18">{{creatorInfo.content}}</h4>
                                            <p class="mb-0 mt-1 text-muted">contents</p>
                                        </div>
                                        <div class="col-4">
                                            <h4 class="fs-18">{{creatorInfo.plays}}</h4>
                                            <p class="mb-0 mt-1 text-muted">plays</p>
                                        </div>
                                        <div class="col-4">
                                            <h4 class="fs-18">{{creatorInfo.player}}</h4>
                                            <p class="mb-0 mt-1 text-muted">Players</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="quiz-creator mt-3">
                <div class="container-fluid p-0">
                    <div class="row w-100" style="margin: auto">
                        <div v-for="(data, index) in quizUser" :key="index" class="col-md-3 p-1">
                            <div class="card mx-2 my-2">
                                <img
                                    v-if="data.images_cover == null"
                                    src="@/assets/images/default-cover.png"
                                    alt=""
                                    class="img-form card-img-top"
                                    @click="detailQuiz(data._id, 1, data.quiz_name)"
                                    type="button"
                                />
                                <img v-else :src="data.images_cover" alt="" class="card-img-top" @click="detailQuiz(data._id, 1, data.quiz_name)" type="button"/>
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between align-items-start">
                                    <h5 class="card-title text-truncate">{{ data.quiz_name ? data.quiz_name : '-' }}</h5>
                                    <b-dropdown size="md"  variant="link" toggle-class="text-decoration-none text-dark p-0" no-caret>
                                        <template #button-content>
                                          <b-icon icon="three-dots-vertical"></b-icon>
                                        </template>
                                        <b-dropdown-item @click="share(data._id, 1, data.quiz_name)" class="d-flex align-items-center"><img src="@/assets/images/icons/share-icon.svg" alt="" class="user-svg mr-2">Share</b-dropdown-item>
                                        <b-dropdown-item class="d-flex align-items-center" @click="duplicate(data._id, data.author_quiz)"><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="user-svg mr-2">Duplicate</b-dropdown-item>
                                        <b-dropdown-item class="d-flex align-items-center" @click="modalReport(data._id)"><img src="@/assets/images/icons/report-icon.svg" alt="" class="user-svg mr-2">Report</b-dropdown-item>
                                      </b-dropdown>
                                  </div>
                                  <div class="d-flex justify-content-start mb-2">
                                    <div class="d-flex mr-4">
                                        <img
                                          src="@/assets/images/icons/ic_play_grup_grey.svg"
                                          alt=""
                                          class="user-svg"
                                        />
                                      <p class="ml-1 text-dark">{{data.total_play}}</p>
                                    </div>
                                    <div class="d-flex mr-4">
                                        <img
                                          src="@/assets/images/icons/ic_question_grup_grey.svg"
                                          alt=""
                                          class="user-svg"
                                        />
                                      <p class="ml-1 text-dark">{{ data.total_question }}</p>
                                    </div>
                                    <div class="d-flex">
                                        <img
                                          src="@/assets/images/icons/ic_clock_grup_grey.svg"
                                          alt=""
                                          class="user-svg"
                                        />
                                      <p class="ml-1 text-dark">90m</p>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-between align-items-center mt-3">
                                    <div class="creator-info d-flex align-items-center">
                                      <img
                                        v-if="userInfo.picture == null"
                                        :src="
                                          'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' +
                                          userInfo.name
                                        "
                                        class="ml-2 rounded-circle"
                                        alt=""
                                      />
                                      <img
                                        :src="userInfo.picture"
                                        alt=""
                                        class="ml-2 rounded-circle"
                                        v-else
                                      />
                                      <p class="text-dark ml-1">{{ userInfo.name ? cutName(userInfo.name) : '-'}},</p>
                                      <p class="ml-1 mb-0 text-truncate" style="font-size: 12px">{{ getDate(data.createdAt) }}</p>
                                    </div>
                                    <div class="d-flex align-items-end">
                                      <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start">
                                      <p class="text-dark ml-1 mb-0">{{ data.rating ? data.rating : '0' }}</p>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="report-quiz" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <div class="d-flex justify-content-between my-4 mx-4">
                <div class="text-left d-flex align-items-center">
                    <img src="@/assets/images/icons/report-icon.svg" alt="" class="mr-3 mt-1">
                    <h3 class="font-weight-bold">Laporkan</h3>
                </div>
                <div class="text-right">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('report-quiz')"><b-icon icon="x"></b-icon></button>
                </div>
            </div>
            <div class="mx-4">
                <div>
                    <h4>Mengapa kamu melaporkan quiz ini?</h4>
                    <div v-for="(data, index) in optionReport" :key="index" class="mt-3">
                        <div class="form-check d-flex align-items-center my-2">
                            <input class="form-check-input" type="radio" name="exampleRadios" :id="'report' + index" v-model="formReport.report" :value="data.text" checked>
                            <label class="form-check-label ml-2" :for="'report' + index">
                                <h5 class="font-weight-normal">{{ data.text }}</h5>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="pt-3">
                    <h4>Deskripsi</h4>
                    <div class="form-group">
                        <label for="deskripsiReport"></label>
                        <textarea v-model="formReport.detail_report" class="form-control" id="deskripsiReport" rows="3"></textarea>
                    </div>
                </div>
                <div class="float-right">
                    <div class="d-flex">
                        <button class="btn btn-secondary btn-grey mr-1 text-dark" @click="$bvModal.hide('report-quiz')">Cancel</button>
                        <button class="btn btn-warning text-white" @click="reportQuizViolations(idQuiz)">Submit</button>
                    </div>
                </div>
                <!-- <b-form-group v-slot="{ ariaReport }">
                    <b-form-radio-group
                        size="sm"
                        v-model="reportSelected"
                        :options="optionReport"
                        :aria-describedby="ariaReport"
                        name="plain-stacked"
                        plain
                        stacked
                    ></b-form-radio-group>
                </b-form-group> -->
            </div>
        </b-modal>
    </section>
</template>
<script>
import $ from "jquery"
import {getQuizCreatorByIdUser} from '@/services/quiz-creator/quiz-creator.service'
import {getUserById} from '@/services/user/user.service'
import {getCreatorById} from '@/services/creator/creator.service'
import { reportQuiz } from "@/services/quiz/quiz.service";
import {getQuestions} from '@/services/question/question.service'
import { getQuestionCreator } from '@/services/question-creator/question_creator.service'
import { mapActions } from "vuex";

import Swal from 'sweetalert2';


export default{
    data(){
        return{
            userInfo : [],
            quizUser : [],
            creatorInfo : [],
            userAuth : "",
            idQuiz : null,
            formReport : {
                origin_quiz : null,
                report : null,
                detail_report : null,
            },
            optionReport : [
                {
                    text : 'Pelanggaran hak kekayaan intelektual',
                    value : 1,
                },
                {
                    text : 'Ujaran atau simbol kebencian',
                    value : 2,
                },
                {
                    text : 'Isi konten berisi tidak pantas',
                    value : 3,
                },
                {
                    text : 'Lainnya',
                    value : 4,
                },
            ],
        }
    },
    mounted(){
        $("section.profile-creator").parent().addClass('mx-2');

        const id = this.$route.query.key
        this.getUser(id)
        this.getCreator(id)
        this.getQuiz(id)
        this.getParamsRouteDynamic()
    },
    methods: {
        ...mapActions(["setAuthor"]),

        getParamsRouteDynamic() {
            var split_url = window.location.href;
            var res = split_url.split("/");
            this.userAuth = res[3];
        },
        getUser(id){
            getUserById(id)
            .then((response) => {
                if(response.status && response.status == true){
                    this.userInfo = response.data
                }
            })
        },
        getCreator(id){
            getCreatorById(id)
            .then((response) => {
                if(response.status && response.status == true){
                    this.creatorInfo = response.data
                }
            })
        },
        getQuiz(id){
            getQuizCreatorByIdUser(id)
            .then((response) => {
                if(response.status && response.status == true){
                    this.quizUser = response.data
                }
            })
        },
        getDate(item){
            var publish = new Date(item);

            var seconds = Math.floor((new Date() - publish) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " years ago";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " months ago";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " days ago";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " hours ago";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minutes ago";
            }
            return Math.floor(seconds) + " seconds ago";
        },
        detailQuiz(id, author, name) {
            this.setAuthor(author);

            this.$router.push({
                name: "Detail-Quiz",
                params: { id : id, name : name.replace(/ /g, '-') },
            });
        },
        cutName(item){
            const name = item.split(" ")
            return name[0]
        },
        duplicate(id,author){
            if(author == '0'){
                getQuestions(id).then((response)=>{
                if(response.status){
                    this.$vs.loading.close();
                    this.dataQuestion = response.data;
                    this.$router.push({ name: 'Edit-Template', query: {key: id} })
                    localStorage.setItem('listQuestion', JSON.stringify(this.dataQuestion));
                }
                }).catch((err)=>{
                    this.$vs.loading.close();
                    console.log("data err", err)
                })
            } else {
                getQuestionCreator(id)
                .then((response)=>{
                    if(response.status){
                      this.$vs.loading.close();
                      this.dataQuestion = response.data;
                      this.$router.push({ name: 'Edit-Template', query: {key: id} })
                      localStorage.setItem('listQuestion', JSON.stringify(this.dataQuestion));
                    }
                })
                .catch((err)=>{
                    this.$vs.loading.close();
                    console.log("data err", err)
                })
            }
        },
        share(id, author, name){
            this.linkShare ='https://soca.ai/quiz/detail-quiz/' + id + '/' +  author +  '/' + name.replace(/ /g, '-')

            this.$copyText(this.linkShare).then(function () {
                alert('Berhasil copy link')
                }, function () {
                alert('Gagal untuk copy link')
                })
            // return this.linkShare
        },

        reportQuizViolations(id){
            this.formReport.origin_quiz = "creator"
            reportQuiz(id, this.formReport)
            .then((response) => {
                if(response.status && response.status == true){
                    this.$bvModal.hide('report-quiz')
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Report sukses !',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.formReport = {
                        origin_quiz : null,
                        report : null,
                        detail_report : null,
                    },
                    this.idQuiz = null;
                } else {
                    this.$bvModal.hide('report-quiz')
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Report gagal !',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.formReport = {
                        origin_quiz : null,
                        report : null,
                        detail_report : null,
                    },
                    this.idQuiz = null;
                }
            })
        },

        modalReport(id){
            this.idQuiz = id
            this.$bvModal.show('report-quiz')
        },
    }
}
</script>
<style scoped>
.profile-creator .profile h4 {
    font-size: 18px;
}

.profile-creator .profile p {
    font-size: 14px;
}

.quiz-creator .card {
  border-radius: 15px !important;
}

.quiz-creator p {
  color: #8c8c8c;
  margin: 0;
  font-size: 12px;
}

.card {
    border-radius: 10px 10px 10px 10px;
}
.bg-profile{
    height: 10rem !important;
}
.circle-w{
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-color: #fff;
}
.profile-pic{
    height: 5.5rem;
    width: 5.5rem;
    border-radius: 50%;
}

.creator-info{
  border-radius: 12px;
  height: 25px;
  width: 50%;
  background-color: #F1F1F1;
}
.creator-info img {
  width: 18px;
  height: 18px;
}

.icon-start{
  width: 18px;
  height: 18px;
}

.card-w{
    width: 20rem;
}

.card-img-top {
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

#report-quiz h3{
    font-size: 24px;
}

#report-quiz h4{
    font-size: 20px;
}

#report-quiz h5{
    font-size: 16px;
}

#report-quiz img{
    height: 24px;
    width: 20px;
}


#report-quiz input[type="radio"]{
    width: 22px;
    height: 22px;
}

#report-quiz .card, #report-quiz textarea{
    border-radius : 10px !important;
}

@media (max-width: 1366px){
    .card-w{
        width: 17rem;
    }
}
</style>