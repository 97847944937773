import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getQuestions(id){
    return axios({
        method : 'GET',
        url : ApiRoute.getQuestion + '/'+ id
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message, 
            page:res.data.page, 
            limit:res.data.limit,
            totalPage:res.data.totalPage, 
            totalData:res.data.totalData, 
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
  }
  
  export async function getQuestionsById(id){
    return axios({
        method : 'GET',
        url : ApiRoute.getQuestion + '/detail/'+id,
    }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message,
        }
        return data;
    }).catch((error) => {
        const data = {
            status : false , 
            response : error 
        }
        return data;
    })
  }
  
  export async function addQuestions(formData, id){
    return axios.post(ApiRoute.getQuestion+ '/' + id, formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        console.log(error)
        return data;
    })
  }
  
  export async function updateQuestions(formData, id){
    return axios.put(ApiRoute.getQuestion + '/' + id, formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        console.log(error)
        return data;
    })
  }
  
  export async function deleteQuestions(id){
    return axios.delete(ApiRoute.getQuestion+ '/' + id).then((res) => {
          const data = res.data
          return data;
    }).catch((error) => {
      const data = {status : false , response : error }
      console.log(error)
      return data;
  })
  }

  export async function importQuestions(formData, id) {
    return axios.post(ApiRoute.uploadQuestion+ '/' + id, formData)
    .then(res => {
        const data = res.data
        return data;
    })
    .catch((error) => {
        const data = {status : false , response : error }
        console.log(error)
        return data;
    })
}